export const environment = {
  production: true,
  envName: 'preprod',
  appName: 'NEO RHEA',
  baseUrl: 'https://preprod.neorhea.fr',
  apiBaseUrl: 'https://preprod.api.neorhea.fr',
  storagePath: 'https://preprod.api.neorhea.fr/storage',
  tokenName: 'NeoRheaCaseTokenPreprod',
  tokenAllowedDomains: ['preprod.api.neorhea.fr'],
  enableBugsnag: true,
  bugsnagApiKey: '9634353d93aef80bbeb274e90e045448'
}
